<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="ant-modal-title">
        {{ l("查看打卡轨迹") }}
      </div>
    </div>
    <div id="myMap" style="min-height: 300px;"></div>
  </a-spin>
</template>

<script>
import { AppComponentBase, ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import ReactTable from "@/components/react-table";
import moment from "moment";
import { CommonServiceProxy } from "@/shared/common-service";
import { AppConsts } from "@/abpPro/AppConsts";

const key = "TZ6BZ-TGSK5-BDCIV-Q3I66-TP5A3-HUF4A";

export default {
  mixins: [AppComponentBase, ModalComponentBase],
  name: "trajectory",
  data() {
    return {
      spinning: true,
      trajectory: {},
      // trajectoryList: [],
      // list: [
      //   { lat: 23.132446, lng: 113.270806 }, // 113.270806,23.132446
      //   { lat: 23.131764, lng: 113.264289 }, // 113.264289,23.131764
      //   { lat: 23.128802, lng: 113.259919 }, // 113.259919,23.128802
      //   { lat: 23.125259, lng: 113.255257 }, // 113.255257,23.125259
      //   { lat: 23.115628, lng: 113.247612 }, // 113.247612,23.115628
      // ],
      list: [],
      myMap: null,
    };
  },
  async created() {
    this.fullData();
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.list = this.trajectoryList

    this.list = this.list.map((v, i) => {return {...v, address: `第${i+1}个`}})
    if (this.list.length > 16) {
      let arr = [...this.list]
      arr.splice(0, this.list.length - 16)
      this.list = arr
    }
    // console.log(this.list)
    let scripts = await document.getElementsByTagName("script");
    let sArr = Array.prototype.slice.call(scripts)
    try {
      if (sArr && sArr.length) {
        let flag = false
        sArr.forEach((ele) => {
          if (ele.src === `https://map.qq.com/api/gljs?v=1.exp&key=${key}`) {
            flag = true
          }
        });
        if (!flag) {
          const oScript = document.createElement("script");
          oScript.type = "text/javascript";
          oScript.src = `https://map.qq.com/api/gljs?v=1.exp&key=${key}`;
          document.body.appendChild(oScript);
        }
      }
    } catch (error) {
      console.log(error);
    }
    // console.log('sArr:', sArr);
  },
  mounted() {
    setTimeout(() => {
      try {
        this.initMap2();
      } catch (error) {
        console.log(error)
        setTimeout(() => {
          this.initMap2();
        }, 800)
      }
    }, 800)
      
  },
  methods: {
    initMap2() {
      let path = [];
      let markGeometries = [];
      this.list.map((item, index) => {
        path.push(new TMap.LatLng(item.lat, item.lng));
        markGeometries.push({
          id: `o-${index + 1}`,
          styleId: `mid`,
          position: new TMap.LatLng(item.lat, item.lng),
        });
      });

      if (markGeometries.length === 1) {
        markGeometries[0].styleId = "mid";
      } else {
        markGeometries[0].styleId = "start";
        markGeometries[markGeometries.length - 1].styleId = "end";
      }

      let center = new TMap.LatLng(this.list[0].lat, this.list[0].lng);
      //初始化地图
      this.myMap = new TMap.Map("myMap", {
        zoom: 15,
        center: center,
      });
      this.jsonpGet(markGeometries);
    },
    jsonpGet(markGeometries) {
      let _this = this;
      const slat = this.list[0].lat;
      const slng = this.list[0].lng;
      const elat = this.list[this.list.length - 1].lat;
      const elng = this.list[this.list.length - 1].lng;
      let wayPointArr = [];
      let geometries = [...this.list];
      let wayPoint = "";

      if (markGeometries.length >= 3) {
        geometries.shift();
        geometries.pop();
        // console.log("geometries:", geometries);
        wayPointArr = geometries.map((v) => {
          return `${v.lat},${v.lng}`;
        });
      }

      // console.log("wayPointArr:", wayPointArr);

      if (wayPointArr.length && wayPointArr.length === 1) {
        wayPoint = wayPointArr[0];
      } else if (wayPointArr.length) {
        wayPoint = wayPointArr.join(";");
      }

      // console.log("wayPoint:", wayPoint);

      $.ajax({
        url: `https://apis.map.qq.com/ws/direction/v1/driving/?from=${slat},${slng}&to=${elat},${elng}${
          wayPointArr.length ? `&waypoints=${wayPoint}` : ""
        }&output=jsonp&key=${key}`,
        type: "get",
        dataType: "jsonp", // 请求方式为jsonp
        jsonpCallback: "cb", // 自定义回调函数名
        data: {},
        success: (ret) => {
          console.log("ret:", ret);
          var coors = ret.result.routes[0].polyline,
            pl = [];
          //坐标解压（返回的点串坐标，通过前向差分进行压缩，因此需要解压）
          var kr = 1000000;
          for (var i = 2; i < coors.length; i++) {
            coors[i] = Number(coors[i - 2]) + Number(coors[i]) / kr;
          }
          //将解压后的坐标生成LatLng数组
          for (var i = 0; i < coors.length; i += 2) {
            pl.push(new TMap.LatLng(coors[i], coors[i + 1]));
          }
          _this.displayPolyline(pl); //显示路线
          _this.displayMarkers(markGeometries);
          _this.displayLabel(markGeometries);
          _this.spinning = false
        },
        fail: (err) => {
          console.log(err);
          _this.spinning = false
        },
      });
    },
    displayMarkers(geometries) {
      console.log("displayMarkers:", geometries);
      //标记起终点marker
      var marker = new TMap.MultiMarker({
        id: "marker-layer",
        map: this.myMap,
        styles: {
          start: new TMap.MarkerStyle({
            width: 25,
            height: 35,
            anchor: { x: 16, y: 32 },
            src:
              "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/start.png",
          }),
          end: new TMap.MarkerStyle({
            width: 25,
            height: 35,
            anchor: { x: 16, y: 32 },
            src: "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/end.png",
          }),
          mid: new TMap.MarkerStyle({
            width: 22,
            height: 32,
            anchor: { x: 16, y: 32 },
            src:
              "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png",
          }),
        },
        geometries: [...geometries],
      });
    },
    displayPolyline(pl) {
      //创建 MultiPolyline显示折线
      var polylineLayer = new TMap.MultiPolyline({
        id: "polyline-layer", //图层唯一标识
        map: this.myMap, //绘制到目标地图
        //折线样式定义
        styles: {
          style_blue: new TMap.PolylineStyle({
            color: "rgba(0, 180, 0, 0.85)", //线填充色
            width: 6, //折线宽度
            borderWidth: 5, //边线宽度
            borderColor: "#FFF", //边线颜色
            lineCap: "round", //线端头方式
          }),
        },
        //折线数据定义
        geometries: [
          {
            id: "pl_1", //折线唯一标识，删除时使用
            styleId: "style_blue", //绑定样式名
            paths: pl,
          },
        ],
      });
    },
    displayLabel() { 
      let geometries = this.list.map((v) => {
        return {
          id: "label", //点图形数据的标志信息
          styleId: "label", //样式id
          position: new TMap.LatLng(v.lat, v.lng), //标注点位置
          content: v.address, //标注文本
          properties: {
            //标注点的属性数据
            title: "label",
          },
        };
      });
      let label = new TMap.MultiLabel({
        id: "label-layer",
        map: this.myMap,
        styles: {
          label: new TMap.LabelStyle({
            color: "#3777FF", //颜色属性
            size: 16, //文字大小属性
            offset: { x: -3, y: 10 }, //文字偏移属性单位为像素
            angle: 0, //文字旋转属性
            alignment: "center", //文字水平对齐属性
            verticalAlignment: "middle", //文字垂直对齐属性
          }),
        },
        geometries: geometries,
      });
    },
  },

  beforeDestroy() {
    // let sArr = document.getElementsByTagName("script");
    // try {
    //   if (sArr && sArr.length) {
    //     sArr.forEach((ele) => {
    //       if (ele.src === `https://map.qq.com/api/gljs?v=1.exp&key=${key}`) {
    //         let node = document.getElementsByTagName("body")[0];
    //         node.removeChild(ele);
    //       }
    //     });
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  },
};
</script>

<style scoped lang="less">
.trajectory {
  padding: 20px;
  .w {
    #myMap {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
